// Desc: Scroll to top of the page when the page is changed. This is the resuable component that can be used in any page.

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollTop =()=>{
    const {pathname} = useLocation();
    useEffect(()=>{
        window.scrollTo(0,0);   // scroll to top of the page x=0, y =0 when the page is changed
    },[pathname]);

    return null;
}

export default ScrollTop;