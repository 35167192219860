
"use client"
import React from 'react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const logos = [
  { name_company: 'Client 1', logo: '/clients/amway.jpeg', alt: 'AMWAY', name: "AMWAY" },
  { name_company: 'Client 2', logo: '/clients/centene.jpeg', alt: 'Centene Corporation', name: "Centene Corporation" },
  { name_company: 'Client 2', logo: '/clients/cisco.png', alt: 'CISCO', name: "CISCO" },
  { name_company: 'Client 2', logo: '/clients/ec_infosystems.png', alt: 'EC INFOSYSTEMS', name: "EC INFOSYSTEMS" },
  { name_company: 'Client 2', logo: '/clients/fox_entertainment.png', alt: 'FOX ENTERTAINMENT', name: "FOX ENTERTAINMENT"},
  { name_company: 'Client 2', logo: '/clients/HItachi_logo.png', alt: 'HITACHI', name: "HITACHI" },
  { name_company: 'Client 2', logo: '/clients/jp_morgan_chase.png', alt: 'JPMORGAN CHASE', name: "JPMORGAN CHASE" },
  { name_company: 'Client 2', logo: '/clients/pennsylvania_department.jpeg', alt: 'Pennysylvania Department', name: "Pennysylvania Department" },
  { name_company: 'Client 2', logo: '/clients/National_broadcast_company.png', alt: 'NATIONAL BROADCASTING COMPANY', name: "NATIONAL BROADCASTING COMPANY"},
  { name_company: 'Client 2', logo: '/clients/northwestern_mutual.png', alt: 'NORTHWESTERN MUTUAL', name: "NORTHWESTERN MUTUAL" },
  { name_company: 'Client 2', logo: '/clients/OneAmerica_sm_rgb.svg', alt: 'ONE AMERICA', name: "ONE AMERICA" },
  { name_company: 'Client 2', logo: '/clients/panera_bread.png', alt: 'PANERA BREAD', name: "PANERA BREAD" },
  { name_company: 'Client 2', logo: '/clients/Paypal_logo.png', alt: 'Paypal', name: "Paypal" },
  { name_company: 'Client 2', logo: '/clients/service_now.png', alt: 'SERVICENOW', name: "SERVICENOW" },
  { name_company: 'Client 2', logo: '/clients/TRC.png', alt: 'TRC HEALTH CARE', name: "TRC HEALTH CARE " },
  { name_company: 'Client 2', logo: '/clients/verizons.png', alt: 'VERIZON', name: "VERIZON" },

];

const Clients = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const numCols = windowWidth > 768 ? 4 : 2; // Change to 768 for tablet breakpoint

  return (
      <div className="container mx-auto p-4 font-poppins sm:px-3 md:px-5 lg:px-20">
      {/* seo */}
      <Helmet>
  <title>Clients | Refyn Data</title>
  <meta name="description" content="Partner with Refyn Data to meet your IT staffing needs. Contact us for customized recruitment solutions that fit your business goals." />
  <meta name="keywords" content="IT staffing solutions, IT recruitment services, business partnerships, Refyn Data clients" />
  
  {/* Open Graph Tags */}
  <meta property="og:title" content="Clients | Refyn Data" />
  <meta property="og:description" content="Refyn Data offers top IT staffing solutions. Partner with us for customized recruitment strategies tailored to your business needs." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.refyndata.com/clients" />
  <meta property="og:image" content="https://www.refyndata.com/images/clients.jpg" />
  
  {/* Twitter Card Tags */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Clients | Refyn Data" />
  <meta property="twitter:description" content="Explore IT staffing and recruitment solutions tailored for your business. Partner with Refyn Data for success." />
  <meta property="twitter:image" content="https://www.refyndata.com/images/clients.jpg" />
</Helmet>


        <div className="mb-10">
        <h1 className="text-md font-bold"><Link to="/" >Home / Client</Link></h1>
        </div>
        <div className="">
          <h2 className="text-3xl font-bold "> Our Clients</h2>
          <div className="flex-grow border-b border-gray-400"></div>
          {/* <hr className="border-b-2 border-gray-400 mx-auto w-32" /> */}
          <p className=' lg:w-[100%] text-[16px] sm:text-sm md:text-lg lg:text-[18px] py-3'>At Refyn Data, our clients are at the heart of everything we do. Partner with us for personalized solutions tailored to your business needs. Experience efficiency, reliability, and a workforce that propels your success forward.</p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8 ">
          {logos.map((logo, index) => (
            <div key={index} className="flex flex-col items-center justify-center  right-0 bottom-0 shadow my-auto  p-3 md:p-4 lg:p-5">
               <div className="flex items-center justify-center h-full">

              <img src={logo.logo} alt={logo.alt} className="my-auto" />
              </div>
              <div className="mt-2  text-center">
                <p className='text-[15px]'>{logo.name}</p>
              </div>
            </div>
          ))}
        </div>

      </div>
  );
};

export default Clients;


