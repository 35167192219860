// Desc: 404, page not found component. This is the resuable component...

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <div className=" w-screen text-center my-10 sm:my-16 md:my-20 font-poppins ">

            {/* Seo for page not found */}
            <Helmet>
                <title>Page Not Found | Refyn Data</title>
                <meta name="description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
                <meta name="keywords" content="IT staffing agency, IT recruitment agency, contact us, Refyn Data" />
                <meta property="og:title" content="Contact Us | Refyn Data" />
                <meta property="og:description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.refyndata.com/contact-us" />
                <meta property="og:image" content="https://www.refyndata.com/images/contact-us.jpg" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Contact Us | Refyn Data" />
                <meta property="twitter:description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
                <meta property="twitter:image" content="https://www.refyndata.com/images/contact-us.jpg" />
            </Helmet>

            <div className='text-[#022452]'>
                <h1 className='text-4xl font-bold'>Refyn Data</h1>
                <p className="text-xl font-semibold">We Redefine Your World</p>
            </div>

            <h1 className='my-auto text-3xl font-bold p-5'> *404 - Page Not Found</h1>
            <p className='text-2xl py-5'> Sorry the page you are looking for does not exist.</p>
            <p className='my-5  font-semibold'><Link to="/" className='border-2 border-gray-500 rounded-lg px-4 py-2  mt-2' >/Go Back to Home</Link></p>
        </div>
    )
}

export default NotFound;