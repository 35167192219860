import React from 'react'
import Navbar from './components/reusable/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Careers from './pages/Careers';
import Clients from './pages/Clients';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Footer from './components/reusable/Footer';
import ScrollTop from './components/reusable/ScrollTop';
import NotFound from './components/reusable/NotFound';

function App() {
  return (
    <div >
      <ScrollTop/>
      <Navbar/>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact-us" element={<Contact />} />

      {/* 404 page  */}
      <Route path="*" element ={<NotFound/>}/>
      </Routes>
      <Footer/>
      
    </div>
    
  )
}

export default App
