import { useState , useEffect} from 'react';
import servicesData from '../components/data/services.json';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {useParams, useNavigate} from 'react-router-dom'

const Services = () => {

  // using useParams and Navigate
  const { serviceId } = useParams();
  const navigate = useNavigate();

// find the service based on the ID from the URL
  const [selectedService, setSelectedService] = useState(() =>
    servicesData.services.find(service=>service.Id===serviceId) || servicesData.service[0]
);
useEffect(() => {
  const service = servicesData.services.find(service => service.id === serviceId);
  if (service) {
    setSelectedService(service);
  } else {
    navigate('/services'); // Redirect if the serviceId is not found
  }
}, [serviceId, navigate]);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    navigate(`/services#${service.id}`); // Update URL
    document.getElementById(service.id)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container mx-auto p-4 font-poppins lg:px-5">

{/*seo*/}
<Helmet>
  <title>Our Services | Refyn Data</title>
  <meta name="description" content="Explore Refyn Data's specialized to meet your software needs, IT staffing and recruitment services. Tailored solutions to meet your business's IT needs. Partner with us for success." />
  <meta name="keywords" content="IT services, IT staffing, IT recruitment, IT consulting, business solutions, Consulting, Cloud computing, Artificial Intelligence and machine learning, software development, Mobile app development,  Refyn Data services" />
  
  {/* Open Graph Tags */}
  <meta property="og:title" content="Our Services | Refyn Data" />
  <meta property="og:description" content="Discover Refyn Data's wide range of IT services, including staffing and recruitment solutions tailored to your business. Contact us for more details." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.refyndata.com/services" />
  <meta property="og:image" content="https://www.refyndata.com/images/services.jpg" />
  
  {/* Twitter Card Tags */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Our Services | Refyn Data" />
  <meta property="twitter:description" content="Refyn Data offers specialized IT staffing and recruitment services. Explore our range of solutions to support your business's growth." />
  <meta property="twitter:image" content="https://www.refyndata.com/images/services.jpg" />
</Helmet>
      <div className="mb-10">
        <h1 className="text-md font-bold"><Link to="/" >Home / Service</Link></h1>
        <div className="border border-gray-300 my-2"></div>
        <div className="mb-4">
          <h1 className="text-3xl font-bold"></h1>

          <div className="mb-5">


            <p className=" text-sm md:text-lg">
            Our top-of-the-line IT solutions will help you streamline workflows, manage large volumes of critical business data, and meet the ever-changing demands of your customers and other stakeholders. Here are some crucial areas our IT experts specialize in.
            </p>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-[20px] lg:gap-x-[60px]">

        {/* Table of Contents (Left Column) */}

        <div className="md:col-span-1 mb-10">
          <div className='w-full border border-gray-400 rounded-md  md:p-1 lg:p-5'>
            <p className="text-2xl font-bold py-2">Services</p>
            <table className="w-full  ">
              <tbody>
                {servicesData.services.map((service, index) => (
                  <tr key={index} onClick={() => handleServiceSelect(service)} className="cursor-pointer hover:bg-gray-200">
                    <td className="px-8 py-2 font-semibold">{service.topic}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Content (Right Column) */}
        <div className="md:col-span-2  ">
          <div>
            <h2 className='text-3xl font-bold text-gray-800'>Services</h2>
            <p className="border-t border-gray-600 my-3"></p>
            <h3 id={selectedService.id} style={{ scrollMarginTop: '300px' }} className="text-2xl font-bold mb-2">{selectedService.topic}</h3>
            <p className='w-full font-regular font text-[17px] mb-5'>{selectedService.special_line}</p>
            {/* <img src={selectedService.image} alt={selectedService.topic} className="w-[600px] md:h-[400px] my-8 rounded-md" /> */}
            <p className='w-full font-regular font text-sm md:text-lg'>{selectedService.description.paragraph1}</p>
            <br />
            <p className='w-full font-regular font text-sm md:text-lg' >{selectedService.special_line2}</p>
            <ul className='mb-4'>
              {selectedService.points.map((point, index) => (
                <li key={index} className='text-sm md:text-lg '>{point} </li>
              ))}
            </ul>
            <p className='w-full font-regular font text-sm md:text-lg'>{selectedService.description.paragraph2}</p>
            <br />
            <p className='w-full font-regular font text-sm md:text-lg'>{selectedService.description.paragraph3}</p>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Services;
