// Desc: About us page. This page is the about the company Regfyndata. It contains the company's vision, mission, core competencies, and what the company offer to its clients.
import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function About() {
    const topicsAndDescriptions = [
        {
            topic: 'Our core offering',
            id:"core-offering",
            description: ['Our team offers end-to-end deliverables on software development projects, following agile methodologies and practices in all our implementations. We offer cloud, big data, cybersecurity, UI, and mobile app services. We also have a vast, global network of IT professionals with expertise in Systems Architecture, Data Management, Application Development, Project Management, and more.']
        },
        {
            topic: '',
            description: 'Our team will become your software development partner and IT product provider, helping you achieve your objectives and foster business growth and security. If you have software projects, cloud infrastructure, and system security needs, we have you covered.'
        },
        {
            topic: '',
            description: 'Additionally, whether you’re looking for a permanent executive, long-term employees, or interim IT experts, we have the candidate network and recruitment expertise to fulfil your needs.'
        },
        {
            topic: 'Our Vision',
            id:"our-vision",
            description: 'We aim to expand our expertise in the IT and software services industry. We want to offer the finest end-to-end deliverables on software projects and use agile methodologies in our undertakings.'
        },
        {
            topic: '',
            description: 'As an established IT staffing agency, we want to keep innovation at the center of all our undertakings. We wish to consistently pursue new development, trends, and best IT talent recruitment practices.'
        },
        {
            topic: '',
            description: 'We wish to steadily evolve and reinvent the status quo. Continuous pursuit of betterment on all fronts is the only way forward for us.'
        },
        {
            topic: 'Our Mission',
            id:"our-mission",
            description: "We aim to become the leading software development and IT recruitment agency in the U.S. and beyond. As an aspiring software services and technology leader, our goal is to offer unrivaled end-to-end software projects, cybersecurity services, mobile app development, UI design, and more to our users."
        },
        {
            topic: '',
            description: "Our team follows agile methodologies and practices in technology implementation and offer training on various technologies to businesses. We want to become the go-to software solution house for organizations across varied industries."
        },
        {
            topic: '',
            description: "We also create innovative IT staffing solutions and services for our clients. Our focus is on helping IT industry candidates meet their potential, share our industry expertise with our clients, and use creativity to inspire and connect our clients with the right candidates."
        },

        {
            topic: 'What Refyn Data Can Do For You ',
            id:"refyndat-can-do",
            description: "As an IT consulting and software development agency, we specialize in delivering IT products, software solutions, and talented IT recruits to our clients so they can effectively achieve their tech goals."
        },
        {
            topic: '',
            description: 'Our growth and increased focus on the software industry and the use of technology to help our clients has enabled us to expand our services. We provide cybersecurity, mobile app development, cloud services, end-to-end software implementation, and training on the use of various technologies.'
        },
        {
            topic: '',
            description: 'With us in your corner, you can strengthen your cybersecurity and improve everyday business processes with the right software implementation. We can provide you with the IT products you need for continued data protection and business growth.'
        },
        {
            topic: '',
            description: "Our commitment to talent acquisition and retention strategies also sets us apart from other IT industry recruiters. We ensure that our permanent and interim candidates have the skills, expertise, and background you need"
        },
        {
            topic: '',
            description: "We also identify skill gaps and potential pitfalls, course-correcting and offering training sessions along the way to ensure our talent pool is diverse, skilled, and perfect for all types of industries. Our aim is to train people to become long-term IT employees at your organization, helping you derive consistent value. "
        },
        {
            topic: 'Our Core Competencies',
            id:"competencies",
            description: "Our team is an extension of your HR and IT departments as we can help you meet your staffing and software development and support needs, helping you focus on achieving your long-term and short-term goals."
        },

    ];
    return (
        <div className="container mx-auto p-4 font-poppins sm:px-3 md:px-5 lg:px-5">

            {/* seo */}
            <Helmet>
      <title>About Us | Refyn Data</title>
      <meta name="description" content="Refyn Data is a U.S.A. based software development and IT staffing agency with a branch in India. We provide our clients with innovative software development, IT products, and IT staffing solutions. Our team also nurtures in-house, top-tier IT talent to help you outsource your IT services to us." />
      <meta name="keywords" content="IT staffing agency, IT recruitment agency, contact us, Refyn Data" />
      <meta property="og:title" content="About Us | Refyn Data" />
      <meta property="og:description" content="Refyn Data is a U.S.A. based software development and IT staffing agency with a branch in India. We provide our clients with innovative software development, IT products, and IT staffing solutions. Our team also nurtures in-house, top-tier IT talent to help you outsource your IT services to us." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.refyndata.com/about-us" />
      <meta property="og:image" content="/navbar/center.svg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="About Us | Refyn Data" />
      <meta property="twitter:description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
      <meta property="twitter:image" content="/navbar/center.svg" />
    </Helmet>

            <div className="mb-10">
                <h1 className="text-md font-bold"><Link to="/" >Home / About Us</Link></h1>

            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-[20px] lg:gap-x-[60px]">
                {/* Table of Contents */}
                <div className="mb-10 hidden md:block text-sm md:text-lg">
                    <div className="w-full border border-gray-400 rounded-md">
                        <p className="px-2 md:px-3 lg:px-5 py-1 md:py-2 lg:py-3 font-bold text-[23px]">Contents</p>
                        <table className="w-full px-5 mb-5">
                            <tbody>
                                {topicsAndDescriptions
                                    .filter(({ topic}) => topic) // Filters out null or empty topics
                                    .map(({ topic,id}, index) => (
                                        <tr key={index}>
                                          <a href={`#${id}`}><td className="px-8 py-2 font-semibold">{topic}</td></a>  
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Description  */}
                <div className="col-span-2 md:col-span-2 lg:col-span-2">
                    <h3 className="text-3xl font-bold">About Us</h3>
                    <div className="border-t border-gray-600 my-4 text-sm md:text-lg"></div>
                    <p className="text-sm md:text-lg">Refyn Data is a U.S.A. based software development and IT staffing agency with a branch in India. We provide our clients with innovative software development, IT products, and IT staffing solutions. Our team also nurtures in-house, top-tier IT talent to help you outsource your IT services to us.</p><br />
                    {topicsAndDescriptions.map(({ topic, description,id }, index) => (
                        <div key={index} id={id} style={{ scrollMarginTop: '120px' }} className="mb-6 text-sm md:text-lg">
                            <h2 className="text-xl text-sm md:text-lg font-semibold mb-2">{topic}</h2>
                            {topic === 'Our Solutions' ? (
                                <>
                                    <p>{description.split('. ')[0] + '.'}</p>
                                    <br />
                                    <p>{description.split('. ')[1] + '.'}</p>
                                    <br />
                                    <p>{description.split('. ')[2]}</p>
                                </>
                            ) : (
                                <p>{description}</p>
                            )}

                        </div>
                    ))}
                    <div className='text-sm md:text-lg'>
                        <p className='font-semibold'>We offer the following</p>
                        <ol className='list-decimal pl-5'>
                            <li>The latest IT products and software implementation</li>
                            <li>Skilled, future-oriented leadership</li>
                            <li>Exceptional customer service</li>
                            <li>Honesty and integrity in service</li>
                            <li>Long-standing client relationships</li>
                            <li>Dedicated customized services</li>
                            <li>Comprehensive need analysis</li>
                        </ol>
                    </div>


                </div>

            </div>

        </div>
    )
}

export default About


