import React from "react";
import { Link } from "react-router-dom";
import "./flipanimation.css"; // Adjust the path accordingly

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional, adds smooth scrolling animation
  });
};

// Data array containing the service details
const services = [
  {
    topic: "Consultancy",
    image: "./home_image/service_image/consultation.png",
    description:
      "Unlock strategic business insights to transform your organization's performance with expert consultation.",
    link: "/services",
  },
  {
    topic: "Mobile App Development",
    image: "./home_image/service_image/mobile_development.png",
    description:
      "Creating innovative, user-friendly mobile applications for seamless experiences.",
    link: "/services",
  },
  {
    topic: "Project Management",
    image: "./home_image/service_image/project_management.png",
    description:
      "We identify opportunities for the improvement of your entire project management process.",
    link: "/services",
  },
  {
    topic: "Big Data",
    image: "./home_image/service_image/big_data.png",
    description:
      "Harness big data for actionable insights and strategic decision-making.",
    link: "/services",
  },
  {
    topic: "Business Analysis",
    image: "./home_image/service_image/business_analysis.png",
    description:
      "Business analysis uncovers insights for strategic planning and performance improvement.",
    link: "/services",
  },
  {
    topic: "Application Development",
    image: "./home_image/service_image/application_development.png",
    description:
      "We identify opportunities for the improvement of your entire project management process.",
    link: "/services",
  },
];

const CartAnimation = () => {
  return (
    <div className="w-[100%] mx-auto content-center text-center">
      <div className="py-10 font-poppins text-center">
        <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl py-2 mt-5">
          We Expertise On This Fields
        </h1>
        <div className="pb-2 text-sm md:text-lg  w-[85%] mx-4 sm:mx-10 md:mx-auto">
          More than just services, we're your partner. We collaborate with you
          to understand your unique needs and deliver customized solutions that
          drive real results.
        </div>
      </div>

<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 md:gap-12 lg:gap-20 px-[20px] mx-auto md:mx-[40px] lg:mx-[50px]">
  {services.map((service, index) => (
    <div key={index} className="scene w-full flex justify-center">
      <div className="w-[280px] h-[280px] sm:w-[320px] sm:h-[320px] md:w-[350px] md:h-[350px] lg:w-[410px] lg:h-[300px]">
        <div className="card rounded-sm shadow-2xl">
          <div className="card-face front relative">
            <img
              src={service.image}
              alt={service.topic}
              className="rounded-[10px] w-[100%] h-[100%] object-cover absolute top-0 left-0"
            />
            <div className="absolute bottom-0 left-0 w-full flex justify-center items-end">
              <span className="text-white text-xl bg-black bg-opacity-50 w-full text-center py-2 rounded-[10px] font-semibold">
                {service.topic}
              </span>
            </div>
          </div>
          <div className="card-face back flex flex-col justify-center items-center p-5">
            <h3 className="text-xl font-bold">{service.topic}</h3>
            <p className="px-5 py-2">{service.description}</p>
            <Link to={service.link}>
              <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
                More...
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>


    </div>
  );
};

export default CartAnimation;
