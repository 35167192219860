import React from "react";
import { FaLinkedinIn } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="mt-12 md:pl-10 py-10 bg-[#F2F5F8] font-poppins w-full">
      <div className="container mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-12 sm:gap-x-10 md:gap-x-20 lg:gap-x-[30px] px-10 md:px-10 lg:px-5">
          {/* Column 1 */}
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl font-semibold mb-3">Contact</h3>
            <a href="tel:+1 301-591-0989" className="mb-2 md:mb-3 inline-flex items-center transition-transform hover:-translate-y-1 text-[15px] md:text-[16px]">
              <FaPhoneVolume className="mr-2 inline" /> Phone: 626-378-4849
            </a>
            <br />
            <a href="mailto:example@example.com" className="text-[15px] md:text-[16px] mb-2 md:mb-3 inline-block transition-transform hover:-translate-y-1">
              <MdEmail className="mr-2 inline" />Email: hr@refyndata.com
            </a>
            <br />
          </div>

          {/* Column 4 */}
          <div className="mb-4 md:mb-0 text-[#0B1320]">
            <h3 className="text-xl font-semibold mb-3">Follow Us</h3>
            <div className="">
              <a href="https://www.linkedin.com/in/refyn-d-47489b21/" className="inline-flex mb-3 transform transition-transform hover:translate-y-[-5px]" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="mr-2 my-auto text-[#0A66C2]" />LinkedIn
              </a>
            </div>
          </div>
        </div>
        {/* Line below the footer */}
        <div className="border-t border-gray-600 my-4 mx-5">
          <div className="container mx-auto">
            <div className="flex justify-between items-center text-[#0B1320]">
              <p className="text-xs">Refyndata @ 2024 All rights reserved</p>
              <div className="flex space-x-4">
                <a href="#" className="text-xs">
                  Terms and Conditions
                </a>
                <a href="#" className="text-xs">
                  Privacy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
