import React, { useState } from "react";

const HeroSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const fullText =
    "Refyn Data is a software development and IT staffing agency with a global presence, including offices in the U.S. and India. We specialize in providing innovative IT solutions, from Cloud services and Big data to Cybersecurity and Mobile app development, tailored to meet the unique needs of our clients. Our team excels in delivering end-to-end software development projects using Agile methodologies, ensuring flexibility, responsiveness, and continuous improvement.";

  const truncatedText =
    "Refyn Data is a software development and IT staffing agency with a global presence, including offices in the U.S. and India. We specialize in providing innovative IT solutions, from Cloud services and Big data to Cybersecurity and Mobile app development...";

  return (
    <div className="md:py-16 sm:px-3 md:px-5 lg:px-20 bg-[#022452] text-white">
      <div className="container lg:my-[-20px] mx-auto flex flex-col md:flex-row items-center">
        {/* Column 1 */}
        <div className="md:w-[50%] md:mx-3 my-10 md:mb-0 md:mr-4">
          <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold leading-tight mb-4 mx-auto md:mx-[0px] w-[90%] md:w-[90%] h-[180]">
            Empowering Success Through Expert IT Talent Your Gateway to Innovation and Efficiency!
          </h1>
          <div className="md:hidden mx=10">
            <img
              src="/home_image/Hero_section/hero_section.svg"
              alt="UsRefyndataHero"
              className="rounded-[10px] w-[400px] md:w-[100%] lg:w-[95%] mx-auto ml-[-30] py-5 h-full"
            />
          </div>

          {/* Text handling based on screen size */}
          <p className="text-sm md:text-[16px] lg:text-lg mb-6 w-[80%] md:w-[100%] mx-auto md:mx-[0px]">
            {/* Show full text on mobile and laptop screens */}
            <span className="block md:hidden lg:block">{fullText}</span>

            {/* Show truncated text with "Read More" button in tablet view */}
            <span className="hidden md:block lg:hidden">
              {isExpanded ? fullText : truncatedText}
              <button
                onClick={toggleText}
                className="text-blue-400 underline ml-1"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </span>
          </p>
        </div>

        {/* Column 2 */}
        <div className="md:w-2/5 hidden md:block">
          <img
            src="/home_image/Hero_section/hero_section.svg"
            alt="refyndata"
            className="ml-[50px] rounded-[10px] w-[500px] h-[500px] md:w-[475px] md:h-[350px] lg:w-[600px] lg:h-[400px] xl:w-[700px] xl:h-[450px] mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
