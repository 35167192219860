import React from 'react'
// import About from "../components/Home/about.js" 
import Hero from "../components/Home/hero_section.jsx" 
import Services from '../components/Home/service.jsx'

import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div>
      <Helmet>
  <title>Refyn Data - IT Staffing and Recruitment Solutions</title>
  <meta name="description" content="Refyn Data is a software development and IT staffing agency with a global presence, including offices in the U.S. and India. We specialize in providing innovative IT solutions, from Cloud services and Big data to Cybersecurity and Mobile app development, tailored to meet the unique needs of our clients." />
  <meta name="keywords" content="IT staffing solutions, IT recruitment, IT consulting, Refyn Data, IT services" />
  
  {/* Open Graph Tags */}
  <meta property="og:title" content="Refyn Data - IT Staffing and Recruitment Solutions" />
  <meta property="og:description" content="Discover Refyn Data's IT staffing and recruitment services designed to help your business succeed. Contact us for customized IT solutions." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.refyndata.com" />
  <meta property="og:image" content="https://www.refyndata.com/images/homepage-banner.jpg" />
  
  {/* Twitter Card Tags */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Refyn Data - IT Staffing and Recruitment Solutions" />
  <meta property="twitter:description" content="Refyn Data specializes in IT staffing and recruitment services. Partner with us to optimize your IT workforce and reach your business goals." />
  <meta property="twitter:image" content="https://www.refyndata.com/images/homepage-banner.jpg" />
</Helmet>

        <Hero/>
        {/* <About/> */}
        <Services/>
        

      
    </div>
  )
}

export default Home
