



import { useState, useRef, useEffect } from "react";
import careersData from "../components/data/careers.json";
import axios from "axios";
import { Link,useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';



const Careers = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedJob, setSelectedJob] = useState(careersData.careers[0]);

  const {jobId} =useParams();
  const navigate = useNavigate();


  useEffect(() => {
    if (jobId) {
      const job = careersData.careers.find(job => job.id === jobId);
      setSelectedJob(job);
    } else {
      setSelectedJob(careersData.careers[0]);
    }
  }, [jobId]);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
    navigate(`/careers#${job.id}`);
  };

  

  return (
    <div className="container mx-auto p-4 font-poppins sm:px-3 md:px-5 lg:px-5">

{/* metadata for seo */}

<Helmet>
  <title>Careers | Refyn Data</title>
  <meta name="description" content="Explore exciting career opportunities at Refyn Data. Contact us at 626-378-4849 or email hr@refyndata.com to join our team." />
  <meta name="keywords" content="Careers at Refyn Data, IT jobs, IT recruitment, job openings, work with Refyn Data" />
  
  {/* Open Graph Tags */}
  <meta property="og:title" content="Careers | Refyn Data" />
  <meta property="og:description" content="Discover career opportunities at Refyn Data. Join us by applying today or contact us at hr@refyndata.com for more information." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.refyndata.com/careers" />
  <meta property="og:image" content="/navbar/careers.svg" />
  
  {/* Twitter Card Tags */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Careers | Refyn Data" />
  <meta property="twitter:description" content="Join the team at Refyn Data. Check out our current job openings and career opportunities. Contact us for more details." />
  <meta property="twitter:image" content="/navbar/careers.svg" />
</Helmet>


      <div className="mb-10">
        <h1 className="text-md font-bold"><Link to="/" >Home / Career</Link></h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10 lg:gap-16">
        <div className="col-span-1 md:col-span-1">

          {/* table of content */}
          <div className="w-full p-3 md:p-2 lg:p-3  border border-gray-400 rounded-md mb-10">
            <p className=" text-[23px] font-bold">Careers</p>
            <table className="table-auto text-sm md:text-lg">
              <tbody>
                {careersData.careers.map((job, index) => (
                  <tr
                    key={index}
                    onClick={() => handleJobSelect(job)}
                    className="cursor-pointer hover:bg-gray-200"
                  >
                    <td className="px-8 md:px-3 lg:px-8 py-2 font-semibold">{job.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* content of career section */}

        <div className="col-span-2 md:col-span-2">
          <div className="flex">
            <div className="w-full">
              <div>
                <h2 className="text-3xl font-bold text-gray-800">Careers</h2>
                <p className="border-t border-gray-600 my-3"></p>
                <h3 className="text-sm md:text-lg font-bold mb-2">
                  {selectedJob.name}
                </h3>

                {/* location of the job */}
                <p className=" flex py-2 ">
                  <p className="font-semibold pr-2 underline text-sm md:text-lg">Location: </p> <p className="text-sm md:text-lg">{selectedJob.location}</p>
                </p>

                {/* remote eligibility */}
                <p className=" flex  py-2">


                  <p className="font-semibold pr-2 underline text-sm md:text-lg">Remote:</p> <p className="text-sm md:text-lg">{selectedJob.remote_Eligible}</p>
                </p>

                {/* Nature of the job */}
                <p className=" py-2  text-sm md:text-lg">
                 <span className="font-semibold underline ">{selectedJob.nature_of_Job}:</span> Travel involved to client locations within the US.
                </p>

                {/* skills required */}
                <p className="py-2 font-semibold underline text-sm md:text-lg ">
                  Skills Requied
                </p>
                <ul className="pb-2 text-sm md:text-lg">
                  {selectedJob.skillsRequired.map(
                    (skillsRequired, index) => (
                      <li key={index} className="pl-2 ml-2 py-{2px}"> {index + 1}. {skillsRequired}</li>
                    )
                  )}
                </ul>


                {/* JOb Details */}
                <div className="py-3 text-sm md:text-lg">
                  <p className=" py-2 font-semibold underline ">
                    Job Details
                   
                  </p> <p className="font-medium pl-5"></p>


                  <ul>
                    {selectedJob.jobDetails.map(
                      (jobDetails, index) => (
                        <li key={index} className="pl-2 ml-2 py-{2px}"> {index + 1}.<span className="pl-2">{jobDetails}</span> </li>
                      )
                    )}
                  </ul>
                </div>
                {/*Job  Responsibilities */}
                <div className="py-3  text-sm md:text-lg">
                  <p className="py-2 font-semibold underline ">
                    Job Responsibilities
                  </p>
                  <ul>
                    {selectedJob.responsibilities.map(
                      (responsibility, index) => (
                        <li key={index} className="pl-2 ml-2 py-{2px}"> {index + 1}.<span className="pl-2">{responsibility}</span> </li>
                      )
                    )}
                  </ul>
                </div>
                {/* Required qualifications, capabilities, and skills: */}
                <div className="py-3 text-sm md:text-lg">
                  <p className=" py-2  font-semibold underline " >
                   Required skills
                  </p>
                  <ul>
                    {selectedJob.requiredQualifications.map(
                      (requiredQualifications, index) => (
                        <li key={index} className="pl-2 ml-2">{index + 1}.<span className="pl-2">{requiredQualifications}</span> </li>
                      )
                    )}
                  </ul>
                </div>

                {/* Preferred qualifications: */}
                <div className="py-3 text-sm md:text-lg">
                  <p className=" py-2 font-semibold underline ">
                    Preferred qualifications
                  </p>
                  <ul>
                    {selectedJob.preferedQualification.map((preferedQualification, index) => (
                      <li key={index} className="pl-2 ml-2">{index + 1}.<span className="pl-2"> {preferedQualification}</span></li>
                    ))}
                  </ul>
                </div>


                {/* Apply fo the position through email */}
                <div className="mt-6">
                  <h3 className="text-2xl font-semibold mb-5 ml-[-10px]">
                    Apply now for this position
                  </h3>
                    <p><a href="mailto:hr@refyndata.com" className="mb-3 text-[#0079A0] ">Email: hr@refyndata.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
