


import React from "react";
import { Link } from 'react-router-dom';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Helmet } from 'react-helmet';


export default function Contact() {
  return (
    <div className="container mx-auto p-4 font-poppins sm:px-3 md:px-5 lg:px-20">

      {/* SEO */}
    <Helmet>
      <title>Contact Us | Refyn Data</title>
      <meta name="description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
      <meta name="keywords" content="IT staffing agency, IT recruitment agency, contact us, Refyn Data" />
      <meta property="og:title" content="Contact Us | Refyn Data" />
      <meta property="og:description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.refyndata.com/contact-us" />
      <meta property="og:image" content="https://www.refyndata.com/images/contact-us.jpg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content="Contact Us | Refyn Data" />
      <meta property="twitter:description" content="Contact Us at Refyn Data. Reach out at 626-378-4849 or email us at hr@refyndata.com for inquiries and support." />
      <meta property="twitter:image" content="https://www.refyndata.com/images/contact-us.jpg" />
    </Helmet>

      <div className="mb-10 mx-auto">
        <h1 className="text-md font-bold"><Link to="/" >Home / Contact</Link></h1>
      </div>
      <div className="mb-4 mx-auto">
          <h1 className="text-3xl font-bold">Contact Us</h1>
          <div className="border border-gray-300 my-2"></div>
          <div className="mb-5">
      
        
        <p className="text-sm md:text-lg">
          If you’re looking for a reliable software and technology provider and IT staffing agency, look no further than Refyn Data. We will fulfill your software needs and provide you with the ideal candidate for your business.
        </p>
      </div>
        </div>

      


      {/*  Address and location*/}
      <div className="grid grid-cols-1 md:grid-cols-3  gap-x-1 md:gap-x-4 lg:gap-x-20 my-5 md:px-2 lg:px-0">

        {/*Registered Office  */}
        <Link to="https://maps.app.goo.gl/8VhdYJpt6cyVfLPn6" target="_blank" rel="noopener noreferrer">
          <div className=" mx-[5px] md:mx-[10px] lg:mx-[30px] text-center ">
            <img
              src="/contact_us/register.png"
              alt="nnovative Cente"
              className=" w-70 h-50 rounded-lg shadow-md mb-2 items-center"
              
            />
            <p className="text-xl mb-1 font-semibold">Registered Office</p>
            <p className="text-sm  mb-1 font-semibold">11504 ELK HORN DRIVE,<br></br>CLARKSBURG-MD-20871{" "}</p>
            <p className="text-sm mb-5">
              {/* 11504 ELKHORN DRIVE,CLARKSBURG-MD-20871{" "} */}
            </p>
          </div></Link>

        {/* Corporate Office: */}
        <Link to="https://maps.app.goo.gl/qQoeapZDNDp2uduZ6" target="_blank" rel="noopener noreferrer">
          <div className=" mx-[5px] md:mx-[10px] lg:mx-[30px] text-center">
            <img
              src="/contact_us/cororate.png"
              alt="nnovative Cente"
              className=" w-70 h-50 rounded-lg shadow-md mb-2"
            />
            <p className="text-xl mb-1 font-semibold">Corporate Office</p>
            <p className="text-sm  mb-1 font-semibold"></p>
            <p className="text-sm mb-5 font-semibold">
            13800 Coppermine Road,Suite  #162,Herndon,VA-20171{" "}
            </p>
          </div>
        </Link>

        {/* Innovative Center: */}
        <Link to="https://maps.app.goo.gl/m9CeNJ8Cj2sczYhu6" target="_blank" rel="noopener noreferrer">
          <div className="mx-[5px] md:mx-[10px] lg:mx-[30px] text-center">
            <img
              src="/contact_us/visakhapatnam.png"
              alt="nnovative Cente"
              className=" w-70 h-50 rounded-lg shadow-md mb-2"
            />
            <p className="text-xl mb-1 font-semibold">Innovative Center</p>
            <p className="text-sm mb-1 font-semibold"></p>
            <p className="text-sm font-semibold">
              A-hub, Andhra University incubation center,AU North
              Campus, Visakhapatnam, AP-530003{" "}
            </p>
          </div>
        </Link>
      </div>
      <div className="mx-[10px] lg:mx-[30px]">
      <a href="tel:+1 301-591-0989" className="flex items-center mb-3">
            <FaPhoneVolume className="mr-2" />Phone:
             626-378-4849  
          </a>
           <a href="mailto:example@example.com" className="flex items-center  mb-3">
      <MdEmail className="mr-2" />Email:
      hr@refyndata.com
    </a></div>
    </div>

  );
}


